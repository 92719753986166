import React, { useState, useEffect, useCallback } from "react";
import styled, { useTheme } from "styled-components";
import { requestAddParticipants } from "../../api/httpapi";
import { useAppStateContext } from "../../providers/AppStateProvider";
import { useInSessionWaitingRoomContext } from "./InSessionWaitingRoomProvider";
import useVideoContext from "../../hooks/useVideoContext";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../../hooks/useCopy";
import AddToSessionModal from "./AddToSessionModal";
import { H2, CloseButton } from "@sussex/react-kit/elements";
import ClientItem from "./ClientItem";
import TherapistAvailability from "../TherapistAvailability";
import { WaitingRoomChat, useChatContext } from "../Chat";
import { datadogEvent } from "../../datadog";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Body = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  flex: 1 0 auto;
  padding-top: 16px;
`;

const ClientWrapper = styled.div`
  padding: 8px;
  margin: 0 16px 16px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
`;

const AtCapacity = styled.div`
  margin: 0 18px;
  padding: 20px 0;
  font-size: ${({ theme }) => theme.fontSize.large};
  font-family: ${({ theme }) => theme.fonts.primary};
  text-align: left;
`;

const Empty = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const FooterWrapper = styled.div`
  padding: 16px;
  width: calc(100% - 40px);
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  font-size: ${({ theme }) => theme.fontSize.normal};
  font-family: ${({ theme }) => theme.fonts.primary};
  margin: -1px 0 0;
  text-align: left;
  font-weight: normal;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  display: flex;
  flex: 0 0 auto;
  align-items: center;
`;

const Header = styled.div`
  position: relative;
  padding: 24px 16px 23px;
  max-height: 72px;
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

export default function InSessionWaitingRoom({
  waitingRoomOpen,
  setWaitingRoomOpen,
}) {
  const {
    state: { waitingRoom },
  } = useAppStateContext();
  const { participants } = waitingRoom;
  const [seenParticipants, setSeenParticipants] = useState(participants);
  const { setNotification } = useInSessionWaitingRoomContext();
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [loadingParticipantId, setLoadingParticipantId] = useState("");
  const [chatOpen, setChatOpen] = useState(false);
  const { participants: inSessionParticipants } = useVideoContext();
  const maxParticipants = process.env.REACT_APP_MAX_PARTICIPANTS - 1;
  const { setActiveChannel, getUnreadCount, isActiveChannel } =
    useChatContext();
  const theme = useTheme();
  const [titleText, fullText, emptyText] = useCopy([
    "sessioncontrols.waitingroom.title",
    "sessioncontrols.waitingroom.full",
    "sessioncontrols.waitingroom.emptyRoom",
  ]);

  const atCapacity =
    maxParticipants && inSessionParticipants.length === maxParticipants;
  const empty = participants.length === 0;

  const closePanel = () => {
    setWaitingRoomOpen(false);
  };

  const closeChat = useCallback(() => {
    if (chatOpen) {
      setChatOpen(false);
    }
  }, [chatOpen]);

  const handleAddClick = (participant, componentName) => {
    datadogEvent({
      category: "session",
      feature: "add_participant",
      event: "clicked",
      component: `InSessionWaitingRoom.${componentName}`,
      metadata: {
        participant: participant.participantId,
      },
    });

    setSelectedParticipant(participant);
    setConfirmationOpen(true);
  };

  const addToSession = async () => {
    const participantId = selectedParticipant.participantId;

    setLoadingParticipantId(participantId);
    const added = await requestAddParticipants([participantId]);

    if (added) {
      datadogEvent({
        category: "session",
        event: "participant_added",
        component: "InSessionWaitingRoom",
        metadata: {
          participant: participantId,
        },
      });
    }

    setLoadingParticipantId("");
    setConfirmationOpen(false);
    closeChat();
    closePanel();
  };

  // Track seen participants while waiting room is open.
  useEffect(() => {
    if (waitingRoomOpen) {
      setSeenParticipants(prev =>
        prev.concat(participants.map(p => p.participantId)),
      );

      const chatParticipantFound = participants.find(p =>
        isActiveChannel(p.participantId),
      );

      if (!chatParticipantFound) {
        closeChat(false);
      }
    }
  }, [participants, waitingRoomOpen, isActiveChannel, closeChat]);

  // Increment unseen count while waiting room is closed.
  useEffect(() => {
    if (!waitingRoomOpen) {
      const newParticipants = participants.reduce((accum, curr) => {
        if (!seenParticipants.includes(curr.participantId)) {
          return accum + 1;
        }
        return accum;
      }, 0);
      const unreadMessages = participants.reduce((accum, curr) => {
        return accum + getUnreadCount(curr.participantId);
      }, 0);
      setNotification(newParticipants + unreadMessages > 0);
    }
  }, [
    waitingRoomOpen,
    setNotification,
    seenParticipants,
    participants,
    getUnreadCount,
  ]);

  // Reset unseen count on close.
  useEffect(() => {
    if (waitingRoomOpen) {
      setNotification(false);
    }
  }, [waitingRoomOpen, setNotification]);

  const chatParticipant =
    participants.filter(p => {
      return isActiveChannel(p.participantId);
    })[0] || null;

  // if chat is active show chat window
  if (chatParticipant) {
    return (
      <>
        <WaitingRoomChat
          isTherapist={true}
          participant={chatParticipant}
          channel={chatParticipant.participantId}
          onAdd={() => {
            handleAddClick(chatParticipant, "ChatHeader");
          }}
          onClose={closePanel}
          onBack={closeChat}
          noSlideout
          insets={{
            mobile: 175,
            tablet: 187,
            tabletPortrait: 187,
          }}
        />
        {confirmationOpen && (
          <AddToSessionModal
            onConfirm={addToSession}
            onClose={() => setConfirmationOpen(false)}
            participant={selectedParticipant}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Container>
        <Header>
          <H2>{titleText}</H2>
          {setWaitingRoomOpen && (
            <ButtonWrapper>
              <CloseButton
                width="16px"
                height="16px"
                close={() => setWaitingRoomOpen(false)}
                fill={theme.colors.default}
                id="panelCloseButton"
              />
            </ButtonWrapper>
          )}
        </Header>
        <Body>
          {participants.map(p => (
            <ClientWrapper key={p.participantId}>
              <ClientItem
                participant={p}
                onAdd={() => handleAddClick(p, "ClientItem")}
                addDisabled={atCapacity}
                onChat={() => {
                  setActiveChannel(p.participantId);
                  setChatOpen(true);
                }}
                chatDisabled={false}
                loading={loadingParticipantId === p.participantId}
              />
            </ClientWrapper>
          ))}
          {empty && <Empty>{emptyText}</Empty>}
          {!empty && atCapacity && (
            <AtCapacity>
              {swapCopyVariables(fullText, {
                MAX: maxParticipants,
              })}
            </AtCapacity>
          )}
        </Body>
        <FooterWrapper>
          <TherapistAvailability isTherapist={true} photoSize="50px" />
        </FooterWrapper>
      </Container>
      {confirmationOpen && (
        <AddToSessionModal
          onConfirm={addToSession}
          onClose={() => setConfirmationOpen(false)}
          participant={selectedParticipant}
        />
      )}
    </>
  );
}
