import React from "react";
import styled from "styled-components";

const Badge = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: ${({ thumbnail }) =>
    thumbnail ? "translate(-50%, -85%)" : "translate(-50%, -50%)"};
  color: white;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-weight: normal;
  background-color: ${({ theme }) => theme.colors.primaryBlue};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  text-align: center;
`;

export default function InitialsBadge({ name, thumbnail }) {
  const initial = name[0] ? name[0].toUpperCase() : "";
  return <Badge thumbnail={thumbnail}>{initial}</Badge>;
}
