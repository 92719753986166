import React, { useCallback } from "react";
import EventBus from "../../eventBus";
import styled, { useTheme } from "styled-components";
import useWindowSize from "../../hooks/useWindowSize";
import { useChatContext } from "./ChatProvider";
import useVideoContext from "../../hooks/useVideoContext";
import { useAppStateContext } from "../../providers/AppStateProvider";
import useCopy from "../../hooks/useCopy";
import { H2, CloseButton } from "@sussex/react-kit/elements";
import ChatLog from "./ChatLog";
import ChatFooter from "./ChatFooter";
import { Panel } from "../SlideoutLayout";

const Header = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  display: flex;
  flex: 0 0 auto;
  align-items: center;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ height, insets }) => height - insets.mobile + "px"};

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: ${({ height, insets }) => height - insets.tablet + "px"};

    @media (orientation: portrait) {
      height: ${({ height, insets }) => height - insets.tabletPortrait + "px"};
    }
  }
`;

const BodyWrap = styled.div`
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
`;

const FooterWrap = styled.div`
  width: 100%;
  background-color: white;
`;

const Chat = ({
  channel,
  insets = {
    mobile: 0,
    tablet: 0,
    tabletPortrait: 0,
  },
}) => {
  const { state } = useAppStateContext();
  const { sendMessage } = useVideoContext();
  const [headerText, placeholder] = useCopy([
    "chatWindow.header",
    "chatWindow.placeholder",
  ]);

  const { getMessages, setActiveChannel } = useChatContext();
  const theme = useTheme();
  const { height } = useWindowSize();
  const chatLog = getMessages(channel);

  const onClose = useCallback(() => {
    setActiveChannel("");
  }, [setActiveChannel]);

  const sendChatMessage = message => {
    sendMessage({
      type: "chat",
      message,
    });

    EventBus.emit("chat", {
      from: state.displayName,
      channel: channel,
      message: message,
      self: true,
    });
  };

  return (
    <Panel
      header={() => (
        <Header>
          <H2>{headerText}</H2>
          <ButtonWrapper>
            <CloseButton
              width="16px"
              height="16px"
              fill={theme.colors.default}
              id="panelCloseButton"
              close={onClose}
            />
          </ButtonWrapper>
        </Header>
      )}
    >
      <Wrap height={height} insets={insets}>
        <BodyWrap>
          <ChatLog messages={chatLog} inSession={true} />
        </BodyWrap>
        <FooterWrap>
          <ChatFooter placeholder={placeholder} onSend={sendChatMessage} />
        </FooterWrap>
      </Wrap>
    </Panel>
  );
};

export default Chat;
