import React, { useState, useEffect } from "react";
import VideoTrack from "../VideoTrack";
import useVideoContext from "../../hooks/useVideoContext";
import styled from "styled-components";
import {
  AudioToggleButton,
  VideoToggleButton,
  SettingsButton,
} from "../SessionControls/Buttons";
import ClientWaitingRoomChatButton from "../ClientWaitingRoomChatButton";
import Error from "./Error";
import Loading from "./Loading";
import { LocalTrackErrors } from "../../providers/VideoProvider/useLocalTracks/useLocalTracks";
import { useAnalyticsContext } from "../../providers/AnalyticsProvider";

const Wrapper = styled.div`
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100%;
`;

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.black};
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  overflow: hidden;
  video {
    object-fit: contain;
    height: 100%;
  }
`;

const EmptyVideo = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.dark};
`;

const MediaControlsWrap = styled.div`
  position: absolute;
  bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  height: 48px;
  width: 48px;
`;

const FloatControlsWrap = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export default function LocalVideoPreview({
  setVideo,
  showMediaControls,
  onMediaSettingsOpen,
  isTherapist,
}) {
  const [videoStarted, setVideoStarted] = useState(false);
  const { localTracks, localTrackError } = useVideoContext();
  const { analyticsEvent } = useAnalyticsContext();
  const videoTrack = localTracks.find(track => track.name.includes("camera"));
  const showVideo =
    videoTrack && (!localTrackError || localTrackError.device === "audio");
  const showSettings =
    onMediaSettingsOpen &&
    ((!localTrackError && videoStarted) ||
      (localTrackError &&
        localTrackError.code !== LocalTrackErrors.Permissions));
  const showControls = showMediaControls && !localTrackError && videoStarted;

  const handleSettingsClick = () => {
    analyticsEvent({
      eventName: "user_action",
      actionType: "click",
      actionName: "camera-audio-settings",
    });
    onMediaSettingsOpen();
  };

  useEffect(() => {
    if (!videoTrack) return;

    if (videoTrack.isStarted) {
      setVideoStarted(true);
      return;
    }

    const onVideoStart = () => {
      setVideoStarted(true);
    };
    videoTrack.on("started", onVideoStart);
    return () => {
      videoTrack.off("started", onVideoStart);
    };
  }, [videoTrack]);

  return (
    <Wrapper>
      <VideoWrapper>
        {showVideo ? (
          <VideoTrack setVideo={setVideo} track={videoTrack} isLocal />
        ) : (
          <EmptyVideo />
        )}
        {localTrackError && (
          <Error
            error={localTrackError}
            onMediaSettingsOpen={onMediaSettingsOpen}
          />
        )}
        {!localTrackError && !videoStarted && <Loading />}
      </VideoWrapper>
      {showControls && (
        <MediaControlsWrap isTherapist={isTherapist}>
          <ButtonWrapper>
            <AudioToggleButton />
          </ButtonWrapper>
          <ButtonWrapper>
            <VideoToggleButton />
          </ButtonWrapper>
          {showSettings && isTherapist ? (
            <ButtonWrapper>
              <SettingsButton onClick={handleSettingsClick} />
            </ButtonWrapper>
          ) : null}
          {!isTherapist ? (
            <ButtonWrapper>
              <ClientWaitingRoomChatButton />
            </ButtonWrapper>
          ) : null}
        </MediaControlsWrap>
      )}
      {showSettings && !isTherapist ? (
        <FloatControlsWrap>
          <ButtonWrapper>
            <SettingsButton onClick={handleSettingsClick} variant="gear" />
          </ButtonWrapper>
        </FloatControlsWrap>
      ) : null}
    </Wrapper>
  );
}
